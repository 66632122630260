














































































































































































































































import { Component, Vue } from 'vue-property-decorator'

@Component({
  head: {
    link: [{ rel: 'stylesheet', href: 'style.css' }],
  },
})
export default class LP extends Vue {}
